import styled from 'styled-components';

import palette from '../../styles/palette';
import media from '../../styles/media';
import typography from '../../styles/typography';

const GreyNoticeText = styled.p`
  color: ${palette.textGrey};
  ${typography.caption}

  ${media.isMobile`
    ${typography.caption}
  `}    
`;

export const GreyNoticeLargeText = styled.p`
  color: ${palette.textGrey};
  ${typography.body1Bold}
`;

export default GreyNoticeText;
