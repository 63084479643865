import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import typography from '../styles/typography';
import palette from '../styles/palette';

import useAuth from '../hooks/useAuth';
import useResponsive from '../hooks/useResponsive';
import useLogin from '../hooks/useLogin';

const Button = styled.a`
  padding: 4px 8px;
  ${typography.body3Bold}
  color: ${palette.primary};
`;

const BackButton = styled(Button)`
  padding: 4px 0;
`;

export default function MobileHeaderRightButton() {
  const { isMobile } = useResponsive();
  const { isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();
  const { pathname } = useLocation();

  const isInListPage = pathname === '/consultations';

  const navigate = useNavigate();

  if (!isMobile) return null;

  if (!isLoggedIn()) {
    return (
      <Button id="login-button" className="header" type="button" onClick={navigateLogin}>
        로그인
      </Button>
    );
  }

  if (isInListPage) {
    return (
      <BackButton type="button" onClick={() => navigate(-1)}>
        {'< 돌아가기'}
      </BackButton>
    );
  }

  return (
    <Button id="mypage-button" href="/consultations">
      상담 기록
    </Button>
  );
}
