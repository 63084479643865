import styled from 'styled-components';
import { ChangeEvent } from 'react';
import typography from '../../styles/typography';
import palette from '../../styles/palette';

const Container = styled.input`
  padding: 0.5em;
  border-radius: 5px;
  border: 1px solid ${palette.containerLightGrey};

  color: ${palette.textBlack};
  ${typography.body1}

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export default function TextInput({ value, handleChangeInput, placeholder } : {
  value: string;
  handleChangeInput: (input: string) => void;
  placeholder: string;
}) {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeInput(e.target.value);
  };
  return (
    <Container value={value} onChange={onChange} placeholder={placeholder} />
  );
}
