import styled from 'styled-components';
import ConsultationCategory from '../../types/ConsultationCategory';
import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

const Container = styled.p<{ category: ConsultationCategory; }>`
  color: ${palette.textBlack};
  white-space: pre-wrap;
  text-align: center;
  ${typography.heading4Mobile}

  span {
    color: ${({ category }) => (consultationCategoryDictionary[category].color.dark)};
  }

  ${media.isMobile`
    padding-block: 8px;
  `}
`;

export default function ExpertRecommendationDescription({ category }: {
  category: ConsultationCategory;
}) {
  if (category === 'legal') {
    return (
      <Container category={category}>
        변호사 상담이 필요하신가요?
        {'\n\n'}
        연락처를 남겨주시면, 노크 팀에서
        {'\n'}
        선생님의 상황을
        {' '}
        <span>면밀히 분석</span>
        한 다음
        {'\n'}
        <span>최고의 변호사</span>
        를 찾아서
        {' '}
        <span>1시간 안에 연락</span>
        드릴게요.
        {'\n\n'}
        (상담 비용은 5 ~ 10만원이에요)
      </Container>
    );
  }
  if (category === 'saju') {
    return (
      <Container category={category}>
        미래가 궁금하신가요?
        {'\n\n'}
        연락처를 남겨주시면, 노크 팀에서
        {'\n'}
        내담자분의 삶을
        {' '}
        <span>면밀히 분석</span>
        한 다음
        {'\n'}
        <span>최고의 역술가</span>
        를 찾아서
        {' '}
        <span>1시간 안에 연락</span>
        드릴게요.
        {'\n\n'}
        (상담 비용은 3 ~ 5만원이에요)
      </Container>
    );
  }
  if (category === 'admission') {
    return (
      <Container category={category}>
        생기부 쓰는 게 막막하신가요?
        {'\n\n'}
        연락처를 남겨주시면, 노크 팀에서
        {'\n'}
        학생의 상황을
        {' '}
        <span>면밀히 분석</span>
        한 다음
        {'\n'}
        <span>최고의 컨설턴트</span>
        를 찾아서
        {' '}
        <span>1시간 안에 연락</span>
        드릴게요.
        {'\n\n'}
        (전화 상담 비용은 무료예요)
      </Container>
    );
  }
  if (category === 'medical') {
    return (
      <Container category={category}>
        병원을 찾고 계신가요?
        {'\n\n'}
        연락처를 남겨주시면, 노크 팀에서
        {'\n'}
        환자분의 증상을
        {' '}
        <span>면밀히 분석</span>
        한 다음
        {'\n'}
        <span>최고의 병원</span>
        을 찾아서
        {' '}
        <span>1시간 안에 연락</span>
        드릴게요.
        {'\n\n'}
        (추천 비용은 무료예요)
      </Container>
    );
  }
  if (category === 'insurance') {
    return (
      <Container category={category}>
        보험 상담이 필요하신가요?
        {'\n\n'}
        연락처를 남겨주시면, 노크 팀에서
        {'\n'}
        고객님의 상황을
        {' '}
        <span>면밀히 분석</span>
        한 다음
        {'\n'}
        <span>최고의 보험설계사</span>
        를 찾아서
        {' '}
        <span>1시간 안에 연락</span>
        드릴게요.
        {'\n\n'}
        (상담 비용은 무료예요)
      </Container>
    );
  }
  return null;
}
