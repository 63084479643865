import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import consultationCategoryDictionary, { categories } from '../data/consultationCategoryDictionary';

import ConsultationCategory from '../types/ConsultationCategory';
import typography from '../styles/typography';
import palette from '../styles/palette';
import media from '../styles/media';

const categoryTabWidth:{
  [Key in ConsultationCategory] : number
} = {
  legal: 120,
  medical: 120,
  saju: 120,
  admission: 120,
  insurance: 120,
  mumul: 140,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.isMobile`
    justify-content: flex-start;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const Navigator = styled.nav`
  width: 100%;
  max-width: 55em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.isMobile`
    width: fit-content;
    max-width: none;
    padding-inline: 0.25em;
    gap: 0.5em;
    justify-content: center;
  `}
`;

const ExpertTab = styled.ul<{ focus:boolean; category: ConsultationCategory; }>`
  li {
    width: ${({ category }) => `${categoryTabWidth[category]}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625em;
    padding: 0.5em;
    border-radius: 12px 12px 0px 0px;
    background-color: ${({ focus, category }) => (focus ? consultationCategoryDictionary[category].color.superlight : 'transparent')};
    cursor: pointer;

    ${media.isMobile`
      width: fit-content;
    `}

    ${media.isPc`
      &:hover {
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.20);
        background-color: ${({ category }: { category:ConsultationCategory; }) => `${consultationCategoryDictionary[category].color.superlight};`}
      }
    `}  
    
    p {
      white-space: nowrap;
      color: ${palette.textBlack};
      ${typography.body2}
    }

    img {
      width: 24px;
      height: auto;
    }
  }
`;

function RefreshIcon({ category }: {
  category: ConsultationCategory;
}) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3546 5.64375C16.7223 4.0125 14.4822 3 11.9944 3C7.01876 3 3 7.0275 3 12C3 16.9725 7.01876 21 11.9944 21C16.1932 21 19.6942 18.1313 20.6961 14.25H18.3546C17.4315 16.8712 14.9325 18.75 11.9944 18.75C8.26829 18.75 5.24015 15.7237 5.24015 12C5.24015 8.27625 8.26829 5.25 11.9944 5.25C13.863 5.25 15.5291 6.02625 16.7448 7.2525L13.1201 10.875H21V3L18.3546 5.64375Z" fill={consultationCategoryDictionary[category].color.original} />
    </svg>
  );
}

export default function SubHeader() {
  const navigate = useNavigate();
  const location = useLocation();

  const isCurrentCategoryPage = (category: ConsultationCategory) => location.pathname.startsWith(`/consultations/${category}`);
  const isCurrentCategoryStartPage = (category: ConsultationCategory) => location.pathname === `/consultations/${category}`;

  const handleClickTab = (category: ConsultationCategory) => {
    if (isCurrentCategoryStartPage(category)) return;

    navigate(`/consultations/${category}`);
  };

  const currentTab = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (currentTab.current) {
      currentTab.current.scrollIntoView({ inline: 'center', behavior: 'smooth' });
    }
  }, [location.pathname]);

  return (
    <Container>
      <Navigator>
        {categories.map((category) => {
          const { navigatorCategory, profileImage } = consultationCategoryDictionary[category];
          return (
            <ExpertTab
              id="expert-tab"
              className={category}
              key={category}
              ref={isCurrentCategoryPage(category) ? currentTab : null}
              onClick={() => handleClickTab(category)}
              focus={isCurrentCategoryPage(category)}
              category={category}
            >
              {(isCurrentCategoryPage(category) && !isCurrentCategoryStartPage(category))
                ? (
                  <li>
                    <RefreshIcon category={category} />
                    <p>새로 상담하기</p>
                  </li>
                )
                : (
                  <li>
                    <img src={profileImage} alt={navigatorCategory} />
                    <p>
                      {navigatorCategory}
                    </p>
                  </li>
                )}
            </ExpertTab>
          );
        })}
      </Navigator>
    </Container>
  );
}
