const twelveGanjiTimeTable: {
  [Key in string]: string
} = {
  '23:30(전날) ~ 01:29': '자시',
  '01:30 ~ 03:29': '축시',
  '03:30 ~ 05:29': '인시',
  '05:30 ~ 07:29': '묘시',
  '07:30 ~ 09:29': '진시',
  '09:30 ~ 11:29': '사시',
  '11:30 ~ 13:29': '오시',
  '13:30 ~ 15:29': '미시',
  '15:30 ~ 17:29': '신시',
  '17:30 ~ 19:29': '유시',
  '19:30 ~ 21:29': '술시',
  '21:30 ~ 23:29': '해시',
};

export default twelveGanjiTimeTable;
