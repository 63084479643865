import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export default function useGuestMessageMemory() {
  const [guestMessage, setGuestMessage, removeGuestMessage] = useLocalStorage('GUEST_MESSAGE', '');

  const memorizeGuestMessage = (message: string) => {
    setGuestMessage(message);
  };

  const extractGuestMessage = useCallback(() => {
    const extracted = guestMessage;
    removeGuestMessage();
    return extracted;
  }, [guestMessage, removeGuestMessage]);

  return {
    guestMessage,
    memorizeGuestMessage,
    extractGuestMessage,
  };
}
