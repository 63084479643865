import { useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import useHeaderHeight from '../../hooks/useHeaderHeight';
import useConsultationDetail from '../../hooks/useConsultationDetail';
import useExpertRecommendation from '../../hooks/useExpertRecommendation';
import useAuth from '../../hooks/useAuth';

import ConsultationCategory from '../../types/ConsultationCategory';

import PageLoading from '../common/PageLoading';
import ConsultationPageLayout from './ConsultationPageLayout';
import ConsultationHistory from './ConsultationHistory';
import ClientMessageForm from './ClientMessageForm';
import RecommendationExpertForm from '../expertRecommendation/RecommendationExpertForm';

export default function ConsultationPageContent({
  consultationCategory,
}: {
  consultationCategory: ConsultationCategory
}) {
  const {
    isAnswering,
    isLoading,
    loadConsultationDetail,
  } = useConsultationDetail();

  const {
    authorizeConsultation,
    isConsultationAuthorized,
  } = useAuth();

  const {
    isExpertRecommendationFormOpen,
    initializeExpertRecommendationFormOpen,
  } = useExpertRecommendation();

  const { headerHeight } = useHeaderHeight();
  const { consultationId } = useParams();

  useEffect(() => {
    if (!consultationId) {
      // TODO: 오류 처리
      return () => {};
    }
    authorizeConsultation(consultationId);
    const loadSubscription = loadConsultationDetail(consultationId);

    return () => {
      if (loadSubscription) {
        loadSubscription.unsubscribe();
      }
    };
  }, [authorizeConsultation, consultationId, loadConsultationDetail]);

  useLayoutEffect(() => {
    initializeExpertRecommendationFormOpen();
  }, [initializeExpertRecommendationFormOpen]);

  if (isLoading && !isAnswering) {
    return (
      <PageLoading />
    );
  }

  function renderClientMessageForm() {
    if (!isConsultationAuthorized) {
      return null;
    }

    if (isExpertRecommendationFormOpen) {
      return (
        <RecommendationExpertForm consultationCategory={consultationCategory} />
      );
    }

    return (
      <ClientMessageForm
        isStartConsultationPage={false}
        consultationCategory={consultationCategory}
      />
    );
  }

  return (
    <ConsultationPageLayout headerHeight={headerHeight} className="consultation-page">
      <ConsultationHistory
        isStartConsultationPage={false}
        consultationCategory={consultationCategory}
      />
      {renderClientMessageForm()}
    </ConsultationPageLayout>
  );
}
