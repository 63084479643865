import { useRecoilState, useResetRecoilState } from 'recoil';
import { useCallback } from 'react';
import {
  concatMap, interval, takeWhile,
} from 'rxjs';

import consultationDetailState from '../stores/consultationDetailState';

import fetchConsultationDetail from '../service/fetchConsultationDetail';
import fetchAnswer from '../service/fetchAnswer';

import { MessageRole } from '../types/ConsultationDetail';

export default function useConsultationDetail() {
  const [consultationDetail, setConsultationDetail] = useRecoilState(consultationDetailState);
  const initializeConsultationDetail = useResetRecoilState(consultationDetailState);
  const {
    dialogue, isAnswering, isLoading, id: consultationId,
  } = consultationDetail;

  const lastMessage = dialogue.at(-1);

  const appendMessageToDialogue = useCallback((
    { message, role, functionType = '' } :
    { message: string; role: MessageRole; functionType?: string; },
  ) => {
    setConsultationDetail((prev) => ({
      ...prev,
      dialogue: [...prev.dialogue,
        {
          role, content: message, created_at: new Date().toISOString(), function: functionType,
        }],
    }));
  }, [setConsultationDetail]);

  const startAnswering = () => {
    setConsultationDetail((prev) => ({
      ...prev,
      isAnswering: true,
    }));
  };
  const endAnswering = useCallback(() => {
    setConsultationDetail((prev) => ({
      ...prev,
      isAnswering: false,
    }));
  }, [setConsultationDetail]);

  const loadConsultationDetail = useCallback((id: string) => {
    setConsultationDetail((prev) => ({
      ...prev, isLoading: true,
    }));

    const subscription = interval(1000)
      .pipe(
        concatMap(() => fetchConsultationDetail(id)),
        takeWhile((detail) => (
          detail.isAnswering
        ), true),
      )
      .subscribe((detail) => {
        setConsultationDetail(() => ({
          ...detail,
          isLoading: false,
          isAnswering: detail.isAnswering,
        }));
      });

    return subscription;
  }, [setConsultationDetail]);

  const loadAnswer = useCallback(() => {
    interval(1000)
      .pipe(
      // TODO: fetchAnswer API가 카테고리 통합되면 consultationCategory 제거
        concatMap(() => fetchAnswer(consultationId)),
        takeWhile((response) => response.isAnswering, true),
      )
      .subscribe((response) => {
        if (response.isAnswering) {
          return;
        }

        appendMessageToDialogue({ role: 'expert', message: response.answer, functionType: response.function });
        endAnswering();
      });
  }, [appendMessageToDialogue, consultationId, endAnswering]);

  return {
    consultationDetail,
    consultationId,
    isAnswering,
    isLoading,
    dialogue,
    lastMessage,
    appendMessageToDialogue,
    initializeConsultationDetail,
    loadConsultationDetail,
    loadAnswer,
    startAnswering,
  };
}
