import { createGlobalStyle } from 'styled-components';

import { RecoilRoot } from 'recoil';

import { Helmet } from 'react-helmet';

import Router from './Router';

import Modal from './components/common/Modal';
import { ClipBoardCopyToastContainer } from './components/common/ClipBoardCopyToast';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Kakao: any;
}

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body, input, textarea, button  {
    font-family: 'Pretendard';
  }

  input, textarea  {
    padding: 0;
  }

  label {
    display: block;
  }
  
  a {
    text-decoration: none;
  }
`;

export default function App() {
  return (
    <RecoilRoot>
      <Helmet>
        <script
          src="https://t1.kakaocdn.net/kakao_js_sdk/2.7.2/kakao.min.js"
          integrity="sha384-TiCUE00h649CAMonG018J2ujOgDKW/kVWlChEuu4jK2vxfAAD0eZxzCKakxg55G4"
          crossOrigin="anonymous"
        />
      </Helmet>
      <GlobalStyle />
      <Modal />
      <Router />
      <ClipBoardCopyToastContainer />
    </RecoilRoot>
  );
}
