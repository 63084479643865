import { useRecoilState, useResetRecoilState } from 'recoil';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import clientMessageState from '../stores/clientMessageState';

import useVisit from './useVisit';
import useConsultationDetail from './useConsultationDetail';
import useBirthInput from './saju/useBirthInput';

import createConsultation from '../service/createConsultation';
import createSajuConsultation from '../service/createSajuConsultation';
import createAnswer from '../service/createAnswer';

import ConsultationCategory from '../types/ConsultationCategory';

export default function useClientMessageForm() {
  const [clientMessage, setClientMessage] = useRecoilState(clientMessageState);
  const initializeClientMessage = useResetRecoilState(clientMessageState);
  const { sajuBirth, composeMessageFromBirth } = useBirthInput();
  const {
    consultationId, startAnswering, appendMessageToDialogue, loadAnswer,
  } = useConsultationDetail();

  const { guestId } = useVisit();
  const navigate = useNavigate();
  const clientMessageMaxLength = 2000;

  const updateClientMessage = useCallback((input: string) => {
    if (input.length > clientMessageMaxLength) {
      const truncatedInput = input.substring(0, clientMessageMaxLength);
      setClientMessage(truncatedInput);
      return;
    }
    setClientMessage(input);
  }, [setClientMessage]);

  const initiateAnswer = useCallback((message: string) => {
    startAnswering();
    appendMessageToDialogue({ message, role: 'client' });
    initializeClientMessage();
  }, [appendMessageToDialogue, initializeClientMessage, startAnswering]);

  const consult = useCallback(async (consultationCategory: ConsultationCategory) => {
    if (consultationCategory === 'saju') {
      const sajuMessage = composeMessageFromBirth();
      initiateAnswer(sajuMessage);
      const id = await createSajuConsultation({ sajuMessage, sajuBirth, guestId });
      navigate(`/consultations/saju/${id}`);
      return;
    }

    initiateAnswer(clientMessage);
    const id = await createConsultation({ consultationCategory, message: clientMessage, guestId });
    navigate(`/consultations/${consultationCategory}/${id}`);
  }, [initiateAnswer, clientMessage, guestId, navigate, composeMessageFromBirth, sajuBirth]);

  const ask = useCallback(async () => {
    initiateAnswer(clientMessage);
    await createAnswer({ consultationId, message: clientMessage });
    loadAnswer();
  }, [clientMessage, consultationId, initiateAnswer, loadAnswer]);

  return {
    clientMessage,
    updateClientMessage,
    consult,
    ask,
    initializeClientMessage,
  };
}
