import styled from 'styled-components';
import { useRef } from 'react';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';

import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

import useExpertRecommendation from '../../hooks/useExpertRecommendation';

import ConsultationCategory from '../../types/ConsultationCategory';

import TextInput from '../common/TextInput';
import BackButton from '../common/BackButton';
import RoundButtonByCategory from '../common/RoundButtonByCategory';
import ExpertRecommendationNotice from './ExpertRecommendationNotice';
import ExpertRecommendationDescription from './ExpertRecommendationDescription';

const Container = styled.div`
  padding: 0.5em;
  border-top: 1px solid ${palette.containerLightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  ${media.isMobile`
    padding: 0.5em 0.75em;
  `}
`;

const Head = styled.div`
  width: 100%;
  padding-inline: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  >p {
    color: ${palette.textBlack};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading2Mobile}
      white-space: pre-wrap;
    `}
  }

  ${media.isMobile`
    padding: 0;
  `}
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;

  ${media.isMobile`
    flex-direction: column;
    align-items: center;
  `}
`;

const DescriptionBox = styled.div`
  flex: 1;
`;

const RequestForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25em;

  > p {
    color: ${palette.textBlack};
    ${typography.body1Bold}
  }

  input {
    width: 21.875em;
  }
`;

const RequestBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.isMobile`
    gap: 16px;
  `}
`;

export default function RecommendationExpertForm({ consultationCategory }
: { consultationCategory: ConsultationCategory }) {
  const {
    handleClickCancel, handleClickConfirm, phoneNumber, updatePhoneNumber, isReadyForSubmit,
  } = useExpertRecommendation();
  const isLoadingRef = useRef<boolean>(false);

  return (
    <Container>
      <Head>
        <BackButton
          id="recommendation-cancel-button"
          className={consultationCategory}
          onClick={handleClickCancel}
        />
        <p>
          {consultationCategoryDictionary[consultationCategory].expertRecommendation.expertTitle}
        </p>
        <RoundButtonByCategory
          id="recommendation-confirm-button"
          className={consultationCategory}
          text="제출하기"
          isActive={isReadyForSubmit && !isLoadingRef.current}
          onClick={async () => {
          // 더블클릭 방지 로직
            if (isLoadingRef.current) {
              return;
            }
            isLoadingRef.current = true;
            await handleClickConfirm(consultationCategory);
            isLoadingRef.current = false;
          }}
          consultationCategory={consultationCategory}
        />
      </Head>
      <Body>
        <DescriptionBox>
          <ExpertRecommendationDescription category={consultationCategory} />
        </DescriptionBox>
        <RequestBox>
          <RequestForm>
            <p>전화번호를 입력해주세요.</p>
            <TextInput
              placeholder="매칭 연락을 받을 전화번호"
              value={phoneNumber}
              handleChangeInput={updatePhoneNumber}
            />
          </RequestForm>
          <ExpertRecommendationNotice />
        </RequestBox>
      </Body>
    </Container>
  );
}
