import ConsultationDetail from '../types/ConsultationDetail';
import bokeAPI from './bokeAPI';

export default async function fetchConsultationDetail(id: string): Promise<ConsultationDetail> {
  const { data } = await bokeAPI.get(`/consultations/${id}`);

  return {
    ...data,

    createdAt: new Date(data.createdAt),
  };
}
