import { useCallback } from 'react';

const { KAKAO_REDIRECT_URI } = process.env;

export default function useKakao() {
  const authorize = useCallback((state: Record<string, string | null>) => {
    if (!Kakao.isInitialized()) {
      Kakao.init(process.env.KAKAO_JS_APP_KEY);
    }
    Kakao.Auth.authorize({
      redirectUri: KAKAO_REDIRECT_URI!,
      state: JSON.stringify(state),
    });
  }, []);

  const shareNoke = useCallback(() => {
    if (!Kakao.isInitialized()) {
      Kakao.init(process.env.KAKAO_JS_APP_KEY);
    }

    Kakao.Share.sendCustom({
      templateId: 112804,
    });
  }, []);

  return {
    authorize,
    shareNoke,
  };
}
