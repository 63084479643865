import styled from 'styled-components';
import KakaoLoginButton from '../common/KakaoLoginButton';
import useLogin from '../../hooks/useLogin';
import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';

const Container = styled.div`
  width: 100%;
  border-radius: 10px;
  background: ${palette.containerSuperLightGrey};
  padding-block: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  h4 {
    padding: 8px;
    color: ${palette.textBlack};
    ${typography.heading3}
    white-space: pre-wrap;
    text-align: center;

    ${media.isMobile`
      ${typography.heading2Mobile}
    `}
  }

  ${media.isMobile`
    position: absolute;
    z-index: 8;
    bottom: 55px;
    left: 0;
    width: 100vw;
    padding: 1em 1.5em 0.75em 1.5em;

    background-color: ${palette.containerWhite};
    border-radius: 0.625em 0.625em 0 0;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.3);
  `}

  animation: float 0.3s ease;
  @keyframes float {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

export default function InduceLoginSection() {
  const { navigateLogin } = useLogin();
  return (
    <Container>
      <h4>
        3초만에 로그인하고
        {'\n'}
        무제한 무료로 질문하세요
      </h4>
      <KakaoLoginButton id="login-button" className="after-trial" onClick={navigateLogin} />
    </Container>
  );
}
