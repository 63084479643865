import bokeAPI from './bokeAPI';

export default async function requestExpertRecommendation(
  {
    consultationId, phoneNumber, clientMessage, expertMessage,
  }:
  { consultationId: string; phoneNumber: string; clientMessage: string;expertMessage:string; },
) {
  await bokeAPI.post(`/consultations/${consultationId}/expert-recommendation/confirm`, {
    phoneNumber,
    clientMessage,
    expertMessage,
  });
}
