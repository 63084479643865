import { useState } from 'react';

import { useRecoilState, useResetRecoilState } from 'recoil';
import consultationCategoryDictionary from '../data/consultationCategoryDictionary';

import useConsultationDetail from './useConsultationDetail';

import requestExpertRecommendation from '../service/requestExpertRecommendation';

import ConsultationCategory from '../types/ConsultationCategory';
import isExpertRecommendationFormOpenState from '../stores/isExpertRecommendationFormOpenState';

export default function useExpertRecommendation() {
  const [isExpertRecommendationFormOpen, setExpertRecommendationFormOpen] = useRecoilState(
    isExpertRecommendationFormOpenState,
  );
  const initializeExpertRecommendationFormOpen = useResetRecoilState(
    isExpertRecommendationFormOpenState,
  );
  const openExpertRecommendationForm = () => setExpertRecommendationFormOpen(true);
  const closeExpertRecommendationForm = () => setExpertRecommendationFormOpen(false);

  const [phoneNumber, setPhoneNumber] = useState<string>('010');

  const {
    consultationId, appendMessageToDialogue,
  } = useConsultationDetail();

  const handleClickConfirm = async (category: ConsultationCategory) => {
    const clientMessage = `${consultationCategoryDictionary[category].expertRecommendation.clientRequestMessage}\n\n전화번호: ${phoneNumber}`;
    const expertMessage = `${consultationCategoryDictionary[category].expertRecommendation.expertResponseMessage}\n\n\n추가로 궁금하신 사항이 있으시면 물어봐주세요.`;
    appendMessageToDialogue({ message: clientMessage, role: 'client' });
    appendMessageToDialogue({ message: expertMessage, role: 'expert' });
    closeExpertRecommendationForm();
    await requestExpertRecommendation({
      consultationId,
      phoneNumber,
      clientMessage,
      expertMessage,
    });
  };

  const handleClickCancel = () => {
    closeExpertRecommendationForm();
  };

  const updatePhoneNumber = (input: string) => {
    // 전화번호 유효성 검사: 숫자를 입력하지않거나 11자 초과를 입력하거나 010으로 시작하지않으면 입력 불가
    if (!/^\d*$/.test(input) || input.length > 11 || !input.startsWith('010')) {
      return;
    }

    setPhoneNumber(input);
  };
  const isReadyForSubmit = phoneNumber.length === 11;

  return {
    isExpertRecommendationFormOpen,
    initializeExpertRecommendationFormOpen,
    openExpertRecommendationForm,
    closeExpertRecommendationForm,
    handleClickConfirm,
    handleClickCancel,
    phoneNumber,
    updatePhoneNumber,
    isReadyForSubmit,
  };
}
