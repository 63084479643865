import styled from 'styled-components';
import media from '../../styles/media';
import palette from '../../styles/palette';
import typography from '../../styles/typography';

const QuestionInput = styled.div`
  color: ${palette.textBlack};
  width: 100%;
  display: flex;
  gap: 0.5em;
  align-items: center;
  border-radius: 25px;
  background: ${palette.containerSuperLightGrey};
  padding: 0.375em 0.25em 0.375em 1.5em;
  position: relative;

  ${media.isMobile`
    border-radius: 16px;
    padding: 0.25em 0.25em 0.25em 1em;
  `}

  textarea {
    width: 100%;
    ${typography.body1}
    background: ${palette.containerSuperLightGrey};
    padding: 0;
    resize: none;
    border: none;
    outline: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::placeholder {
      color: ${palette.textGrey};
    }
  }

  ${media.isMobile`
    border-radius: 16px;
    padding: 0.25em 0.25em 0.25em 1em;
  `}
`;

export default QuestionInput;
