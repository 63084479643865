import styled from 'styled-components';

import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import CloseIcon from './CloseIcon';
import ModalActionButton from './ModalActionButton';

const Container = styled.div`
  padding: 0.5em;
  padding-bottom: 2em; 
  min-width: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;

  border-radius: 10px;
  border: 0.5px solid #D9D9D9;
  background-color: ${palette.containerWhite};

  ${media.isMobile`
    padding: 1em;
    align-items: flex-start;
  `}

  p {
    ${typography.body1}
    text-align: center;
    white-space: pre-wrap;
    ${media.isMobile`
      ${typography.body1}
      text-align: left;
      padding-inline: 0.5em;
    `}
  }
`;

const Head = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;

  ${media.isMobile`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  `}

  div {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    ${media.isMobile`
      width: fit-content;
    `}
  }

  h1 {
    ${typography.heading3}
    ${media.isMobile`
      ${typography.heading2Mobile}
      padding-inline: 0.5em;
    `}
  }
`;

const RewriteButton = styled(ModalActionButton)``;

export default function KinWelcomeModal({ onClose } : {
  onClose : () => void;
}) {
  return (
    <Container>
      <Head>
        <div>
          <CloseIcon onClick={onClose} />
        </div>
        <h1>
          지식인 문의 입력 완료
        </h1>
      </Head>
      <p>
        안녕하세요, 노크입니다.
        {'\n\n'}
        지식인 문의가 자동으로 입력되었습니다.
        {'\n\n'}
        지금 바로 상담해보세요!
      </p>
      <RewriteButton type="button" onClick={onClose}>
        네, 상담해볼게요
      </RewriteButton>
    </Container>
  );
}
