import styled from 'styled-components';
import ConsultationCategory from '../../types/ConsultationCategory';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';
import media from '../../styles/media';

const QuestionSubmit = styled.div<{ inactive: boolean, consultationCategory: ConsultationCategory }>`
  height: 100%;
  display: flex;
  align-items: flex-end;
  
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    aspect-ratio: 1;
    background-color: ${({ inactive, consultationCategory }) => (inactive ? 'transparent' : consultationCategoryDictionary[consultationCategory].color.original)};
    padding: 0;
    border-radius: 25px;
    cursor: ${({ inactive }) => (inactive ? 'default' : 'pointer')};
    pointer-events: ${({ inactive }) => (inactive ? 'none' : 'auto')};

    ${media.isPc`
      &:hover {
        background-color: ${({ consultationCategory }: { consultationCategory: ConsultationCategory; }) => (consultationCategoryDictionary[consultationCategory].color.dark)};
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.30);
      }
    `}  
  }
`;

export default QuestionSubmit;
