import styled from 'styled-components';

import palette from '../../styles/palette';
import typography from '../../styles/typography';

const Container = styled.p`
  color: ${palette.textBlack};
  white-space: pre-wrap;
  ${typography.caption}
`;

export default function ExpertRecommendationNotice() {
  return (
    <Container>
      ※ 개인정보는 AES-256 보안기술로 철저하게 보호돼요.
      {'\n'}
      ※ 수집된 개인정보는 상담 목적으로만 이용돼요.
      {'\n'}
      ※ 제출하지 않으시면 상담을 받으실 수 없어요.
    </Container>
  );
}
