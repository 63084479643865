import styled from 'styled-components';
import ExpertCard from '../common/ExpertCard';
import useResponsive from '../../hooks/useResponsive';
import media from '../../styles/media';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  ${media.isMobile`
    width: fit-content;
    gap: 1.5em;
  `}
`;

const Row = styled.div`
  display: flex;
  width: fit-content;
  flex: 1;
  gap: 1em;
`;

export default function ExpertCardSection() {
  const { isMobile } = useResponsive();

  if (isMobile) {
    return (
      <Container>
        <Row>
          <ExpertCard consultationCategory="legal" />
        </Row>
        <Row>
          <ExpertCard consultationCategory="medical" />
          <ExpertCard consultationCategory="admission" />
        </Row>
        <Row>
          <ExpertCard consultationCategory="insurance" />
          <ExpertCard consultationCategory="saju" />
        </Row>
        <Row>
          <ExpertCard consultationCategory="mumul" />
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <ExpertCard consultationCategory="legal" />
        <ExpertCard consultationCategory="medical" />
        <ExpertCard consultationCategory="saju" />
      </Row>
      <Row>
        <ExpertCard consultationCategory="admission" />
        <ExpertCard consultationCategory="insurance" />
        <ExpertCard consultationCategory="mumul" />
      </Row>
    </Container>
  );
}
