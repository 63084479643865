import styled from 'styled-components';

import palette from '../../styles/palette';
import media from '../../styles/media';

const Container = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  color: ${palette.containerGrey};

  p {
    color: ${palette.containerGrey};
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
  }

  ${media.isPc`
    &:hover {
      text-decoration: underline;
    }
  `}
`;

export default function BackButton({ onClick, id, className }
: {
  id?: string;
  className?: string;
  onClick: () => void; }) {
  return (
    <Container
      id={id}
      className={className}
      type="button"
      onClick={onClick}
    >
      <p>
        {'<'}
      </p>
      <p>&nbsp;뒤로가기</p>
    </Container>
  );
}

BackButton.defaultProps = {
  id: '',
  className: '',
};
