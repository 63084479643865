import { ToastContainer, Zoom } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import assetSource from '../../data/assetSource';

import typography from '../../styles/typography';
import palette from '../../styles/palette';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
  
  .Toastify__toast {
    position: fixed;
    left: 50px;
    bottom: 150px;
    width: 294px;
    min-height: auto;
    padding: 0.5em 2em;
    border-radius: 5px;
    
    ${typography.body1Bold}
    background-color: ${palette.toastGrey};
    color: ${palette.textWhite};

    button[aria-label='close'] {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;

  div {
    width: 100%;
    text-align: center;
  }
`;

export function ClipBoardCopyToastContainer() {
  return (
    <StyledToastContainer
      transition={Zoom}
      position="bottom-center"
      autoClose={1500}
      hideProgressBar
    />
  );
}

export default function ClipBoardCopyToast() {
  return (
    <Container>
      <img src={`${assetSource}/assets/toast-checkbox-icon.svg`} alt="체크박스" />
      <div>
        링크가 복사되었습니다.
      </div>
    </Container>
  );
}
