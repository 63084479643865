import { atom } from 'recoil';
import { SajuBirth } from '../types/SajuBirth';

const sajuBirthState = atom<SajuBirth>({
  key: 'sajuBirthState',
  default: {
    year: '',
    month: '',
    day: '',
    hour: '',
    gender: '',
    calendar: 'S',
    birthTimeUnknown: false,
  },
});

export default sajuBirthState;
