import { useCallback, useState } from 'react';

import { useLocalStorage } from 'usehooks-ts';

import retrieveAccessToken from '../service/retrieveAccessToken';
import checkConsultationAuthorization from '../service/checkConsultationAuthorization';

import { Message } from '../types/ConsultationDetail';

export default function useAuth() {
  const [storedId] = useLocalStorage('GUEST_ID', '');
  const [isConsultationAuthorized, setIsConsultationAuthorized] = useState<boolean>(true);

  const isLoggedIn = useCallback(() => {
    const token = retrieveAccessToken();
    return Boolean(token);
  }, []);

  const authorizeConsultation = useCallback(async (consultationId : string) => {
    const isAuthorized = await checkConsultationAuthorization(
      { guestId: storedId, consultationId },
    );

    setIsConsultationAuthorized(isAuthorized);
  }, [storedId]);

  const isGuestTrialEnd = useCallback(
    (messages: Message[]) => (
      !isLoggedIn() && messages.length >= 10
    ),
    [isLoggedIn],
  );

  return {
    isLoggedIn,
    isGuestTrialEnd,
    authorizeConsultation,
    isConsultationAuthorized,
  };
}
