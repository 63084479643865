import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import requestLogin from '../service/requestLogin';
import saveAccessToken from '../service/saveAccessToken';

import useVisit from './useVisit';
import useConsultationDetail from './useConsultationDetail';
import useGuestMessageMemory from './useGuestMessageMemory';
import useClientMessageForm from './useClientMessageForm';

import { categories } from '../data/consultationCategoryDictionary';

export default function useLogin() {
  const navigate = useNavigate();
  const { consultationDetail } = useConsultationDetail();
  const { guestId, removeGuestId } = useVisit();
  const location = useLocation();
  const { memorizeGuestMessage } = useGuestMessageMemory();
  const { clientMessage } = useClientMessageForm();

  const login = useCallback(async (authorizationCode: string) => {
    const accessToken = await requestLogin(authorizationCode, guestId);
    removeGuestId();
    saveAccessToken(accessToken);
  }, [guestId, removeGuestId]);

  const navigateLogin = useCallback(() => {
    if (clientMessage) {
      memorizeGuestMessage(clientMessage);
    }
    const consultationId = consultationDetail.id;
    const loginQuery = [
      consultationId ? `consultationId=${consultationId}` : '',
      categories.some((category) => `/consultations/${category}` === location.pathname)
    && `category=${location.pathname.split('/').pop()}`,
    ].filter(Boolean).join('&');

    navigate(`/login${loginQuery ? `?${loginQuery}` : ''}`);
  }, [clientMessage, consultationDetail.id, location.pathname, memorizeGuestMessage, navigate]);

  return {
    login,
    navigateLogin,
  };
}
