import { useSearchParams } from 'react-router-dom';

export default function useQueryParams(): Record<string, string | null> {
  const [searchParams] = useSearchParams();

  const parseParams = () => {
    const entries = [...searchParams.entries()];
    const result = entries.reduce((acc, entry) => {
      const [key, value] = entry;
      return {
        ...acc,
        [key]: value,
      };
    }, {});

    return result;
  };

  return parseParams();
}
