import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useQueryParams from '../hooks/useQueryParams';
import useLogin from '../hooks/useLogin';

import Loading from '../components/common/PageLoading';

export default function OAuthPage() {
  const { code: authorizationCode, state } = useQueryParams();

  const { login } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    const { consultationId, category } = JSON.parse(state!);

    if (!authorizationCode) {
      // TODO: 로그인 실패 오류 처리
      throw Error('카카오 로그인 실패!');
    }

    // TODO: 로그인 실패 오류 처리
    login(authorizationCode).then(() => {
      if (consultationId) {
        navigate(`/consultations/${consultationId}`);
        return;
      }

      if (category) {
        navigate(`/consultations/${category}`);
        return;
      }

      navigate('/');
    });
  }, [login, authorizationCode, state, navigate]);

  return (
    <Loading />
  );
}
