import styled from 'styled-components';
import media from '../../styles/media';

const ConsultationPageLayout = styled.div<{ headerHeight: number }>`
  flex: 1;
  max-width: 55em;
  height:  ${({ headerHeight }) => `calc(100svh - ${headerHeight}px)`};
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;

  ${media.isMobile`
    height:  ${({ headerHeight } : { headerHeight: number }) => `calc(100svh - ${headerHeight}px)`};
    padding: 0;
  `}
`;

export default ConsultationPageLayout;
