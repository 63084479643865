import styled from 'styled-components';

import palette from '../../styles/palette';
import typography from '../../styles/typography';

import ConsultationCategory from '../../types/ConsultationCategory';

import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';
import media from '../../styles/media';
import useExpertRecommendation from '../../hooks/useExpertRecommendation';

const Container = styled.button<{ category: ConsultationCategory; inActive:boolean; }>`
  width: 96px;
  height: fit-content;
  flex-shrink: 0;
  padding: 16px 0;
  border-radius: 25px;
  color: ${({ inActive, category }) => (inActive ? palette.textGrey : consultationCategoryDictionary[category].color.dark)};
  background-color: ${({ inActive, category }) => (inActive ? palette.containerSuperLightGrey : consultationCategoryDictionary[category].color.superlight)};
  cursor: pointer;
  pointer-events: ${({ inActive }) => (inActive ? 'none' : 'auto')};
  ${typography.body1Button}
  
  ${media.isMobile`
    width: 72px;
  `}

  ${media.isPc`
      &:hover {
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.30);
      }
  `}
`;

export default function OpenExpertRecommendationFormButton({
  inActive, consultationCategory,
}:{
  inActive:boolean;
  consultationCategory: ConsultationCategory; }) {
  const { openExpertRecommendationForm } = useExpertRecommendation();

  return (
    <Container
      id="recommendation-form-open-button"
      className={consultationCategory}
      inActive={inActive}
      category={consultationCategory}
      disabled={inActive}
      onClick={openExpertRecommendationForm}
    >
      {consultationCategoryDictionary[consultationCategory].expertRecommendation.targetExpert}
    </Container>
  );
}
