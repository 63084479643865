import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import media from '../../styles/media';

import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

import ConsultationCategory from '../../types/ConsultationCategory';

import useIsFirstSajuConsultation from '../../hooks/saju/useIsFirstSajuConsultation';
import useAuth from '../../hooks/useAuth';

import Message from './Message';
import useConsultationDetail from '../../hooks/useConsultationDetail';

const Container = styled.div`
  padding-block: 1em;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  gap: 2em;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    display: none;
  }

  ${media.isMobile`
    padding: 0.75em;
    gap: 1em;
  `}
`;

export default function ConsultationHistory({
  isStartConsultationPage,
  consultationCategory,
}: {
  isStartConsultationPage: boolean;
  consultationCategory: ConsultationCategory;
}) {
  const { dialogue, isAnswering } = useConsultationDetail();

  const {
    loadIsFirstSajuConsultation,
    isFirstSajuConsultation,
  } = useIsFirstSajuConsultation();
  const { isLoggedIn } = useAuth();
  const { consultationId } = useParams();

  useEffect(() => {
    if (consultationCategory !== 'saju' || !isLoggedIn()) return;
    loadIsFirstSajuConsultation(isStartConsultationPage, consultationId);
  }, [consultationCategory,
    consultationId,
    isLoggedIn,
    isStartConsultationPage,
    loadIsFirstSajuConsultation]);

  const getInstruction = () => {
    if (consultationCategory === 'saju' && !isFirstSajuConsultation) {
      return consultationCategoryDictionary[consultationCategory].revisitInstruction;
    }

    return consultationCategoryDictionary[consultationCategory].welcomeInstruction;
  };

  const isFirstRenderRef = useRef<boolean>(true);
  const focusedMessageRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // TODO: 제대로된 로직으로 고치기
    if (isFirstRenderRef.current && isStartConsultationPage) {
      isFirstRenderRef.current = false;
      return;
    }
    isFirstRenderRef.current = false;
    const scrollIntoViewBehavior = isStartConsultationPage ? 'smooth' : 'instant';

    if (focusedMessageRef.current) {
      focusedMessageRef.current.scrollIntoView({ behavior: scrollIntoViewBehavior });
    }
  }, [isStartConsultationPage, isAnswering]);

  return (
    <Container>
      <Message
        messageRole="expert"
        content={getInstruction()}
        category={consultationCategory}
      />
      {dialogue.map((message, index) => (
        <Message
          key={message.created_at}
          messageRole={message.role}
          content={message.content}
          category={consultationCategory}
          focusedMessageRef={(index === dialogue.length - 1 && message.role === 'expert')
            ? focusedMessageRef
            : null}
          isFirstMessage={index === 1}
        />
      ))}
      {isAnswering
        && (
          <Message
            messageRole="loading"
            content=""
            focusedMessageRef={focusedMessageRef}
            category={consultationCategory}
          />
        )}
    </Container>
  );
}
