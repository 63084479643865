import ConsultationCategory from '../types/ConsultationCategory';
import categoryToKorean from '../utils/categoryToKorean';
import bokeAPI from './bokeAPI';

export default async function createConsultation({
  consultationCategory,
  message,
  guestId,
}: {
  consultationCategory: ConsultationCategory;
  message: string;
  guestId: string;
}) {
  const { data } = await bokeAPI.post('/consultations', {
    category: categoryToKorean(consultationCategory),
    message,
    guestId,
  });

  return data;
}
