import styled from 'styled-components';
import { useEffect, useRef } from 'react';

import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import useBirthInput from '../../hooks/saju/useBirthInput';
import useResponsive from '../../hooks/useResponsive';
import useAuth from '../../hooks/useAuth';
import useLogin from '../../hooks/useLogin';
import useVisit from '../../hooks/useVisit';

import checkIsGuestTrialEnd from '../../service/checkIsGuestTrialEnd';

import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

import SajuDropDown from './SajuDropDown';
import InfoCheckBox from '../common/InfoCheckBox';
import useClientMessageForm from '../../hooks/useClientMessageForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0.5em 0.5em 1.5em 0.5em;
  border-top: 1px solid ${palette.containerLightGrey};

  ${media.isMobile`
    padding: 0.5em 0.75em 1em 0.75em;
    gap: 0.5em;
  `}
`;

const Title = styled.div`
  padding-inline: 0.5em;
  display: flex;
  justify-content: space-between;
  
  p {
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading2Mobile}
    `}
  }
`;

const InputSection = styled.div`
  display: flex;
  gap: 0.5em;
  justify-content: center;
  
  ${media.isMobile`
    width: 100%;
    flex-direction: column;
    gap: 1em;
  `}
`;

const BirthDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const BirthTime = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const CheckBoxSection = styled.div`
  display: flex;
  gap: 0.5em;
`;

const DropDownSection = styled.div`
  display: flex;
  gap: 0.5em;
`;

const BirthInfoSubmit = styled.button<{ inactive: boolean; }>`
  padding: 12px 16px;
  border-radius: 25px;
  background-color: ${({ inactive }) => (inactive ? palette.containerLightGrey : consultationCategoryDictionary.saju.color.original)};
  color: ${palette.textWhite};
  ${typography.body1Button}
  pointer-events: ${({ inactive }) => (inactive ? 'none' : 'auto')};
  
  ${media.isPc`
    &:hover {
      cursor: pointer;
      background-color: ${consultationCategoryDictionary.saju.color.dark};
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.30);
    }
  `}

  ${media.isMobile`
    padding: 8px 12px;
  `}
`;

export default function BirthInfoForm() {
  const {
    sajuBirth,
    initializeSajuBirth,
    doneSajuBirthInput,
    updateIsMoonCalendar,
    handleChangeBirthTimeUnknown,
  } = useBirthInput();
  const { consult } = useClientMessageForm();
  const { isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();
  const { guestId } = useVisit();
  const { isMobile } = useResponsive();
  const isLoadingRef = useRef<boolean>(false);

  const handleClickSubmit = async () => {
    // 더블클릭 방지 로직
    if (isLoadingRef.current) {
      return;
    }
    isLoadingRef.current = true;

    if (!isLoggedIn()) {
      const isTrialEnd = await checkIsGuestTrialEnd(guestId);
      if (isTrialEnd) {
        navigateLogin();
        return;
      }
    }
    await consult('saju');

    isLoadingRef.current = false;
  };

  useEffect(() => {
    initializeSajuBirth();
  }, [initializeSajuBirth]);

  return (
    <Container>
      <Title>
        <p>
          {isMobile ? '내 사주 보기' : '사주풀이를 위해 생년월일시와 성별을 선택해주세요.'}
        </p>
        <BirthInfoSubmit inactive={!doneSajuBirthInput()} id="inquiry-button" onClick={handleClickSubmit}>
          풀이하기
        </BirthInfoSubmit>
      </Title>
      <InputSection>
        <BirthDate>
          <CheckBoxSection>
            <InfoCheckBox
              isChecked={sajuBirth.calendar === 'M'}
              handleChangeChecked={updateIsMoonCalendar}
              infoText="음력이예요"
              infoType="info"
            />
          </CheckBoxSection>
          <DropDownSection>
            <SajuDropDown
              selectKey="year"
            />
            <SajuDropDown
              selectKey="month"
            />
            <SajuDropDown
              selectKey="day"
            />
          </DropDownSection>
        </BirthDate>
        <BirthTime>
          <CheckBoxSection>
            <InfoCheckBox
              isChecked={sajuBirth.birthTimeUnknown}
              handleChangeChecked={handleChangeBirthTimeUnknown}
              infoText="시간을 몰라요"
              infoType="alert"
            />
          </CheckBoxSection>
          <DropDownSection>
            <SajuDropDown
              selectKey="hour"
            />
            <SajuDropDown
              selectKey="gender"
            />
          </DropDownSection>
        </BirthTime>
      </InputSection>
    </Container>
  );
}
