import styled from 'styled-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import typography from '../styles/typography';
import palette from '../styles/palette';
import media from '../styles/media';
import ExpertCardSection from '../components/homepage/ExpertCardSection';
import useResponsive from '../hooks/useResponsive';
import useHeaderHeight from '../hooks/useHeaderHeight';
import useQueryParams from '../hooks/useQueryParams';

const Container = styled.div<{ headerHeight: number; }>`
  flex: 1;
  height: ${({ headerHeight }) => `calc(100svh - ${headerHeight}px)`};
  display: flex;  
  flex-direction: column;
  gap: 4em;
  align-items: center;
  justify-content: center;
`;

const TitleSection = styled.div`
  display: flex;  
  flex-direction: column;
  gap: 18px;
`;

const Title = styled.p`
  ${typography.hero}
  color: ${palette.textBlack};
  text-align: center;

  ${media.isMobile`
    ${typography.heroMobile}
  `}
`;

const SubTitle = styled.p`
  ${typography.heading1}
  color: ${palette.textDarkGrey};
  text-align: center;

  ${media.isMobile`
    white-space: pre-line;
    ${typography.heading1Mobile}
  `}
`;

export default function HomePage() {
  const { isMobile } = useResponsive();
  const { headerHeight } = useHeaderHeight();
  const { naverInquiry } = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!naverInquiry) {
      // TODO: 오류 처리
      return;
    }

    navigate(`/consultations/legal?naverInquiry=${naverInquiry}`, { replace: true });
  }, [naverInquiry, navigate]);

  return (
    <Container headerHeight={headerHeight}>
      <TitleSection>
        <Title>고민이 있다면, 노크하세요</Title>
        {
          isMobile
            ? (
              <SubTitle>
                AI 전문가들과
                {'\n'}
                지금 바로 상담해보세요.
              </SubTitle>
            )
            : <SubTitle>AI 전문가들과 지금 바로 무료로 상담해보세요.</SubTitle>
        }
      </TitleSection>
      <ExpertCardSection />
    </Container>
  );
}
