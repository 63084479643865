import { atom } from 'recoil';
import ConsultationDetail from '../types/ConsultationDetail';

const consultationDetailState = atom<ConsultationDetail>({
  key: 'consultationDetailState',
  default: {
    id: '',
    createdAt: '',
    dialogue: [],
    isAnswering: false,
    isLoading: false,
  },
});

export default consultationDetailState;
