import styled from 'styled-components';
import { Ref } from 'react';
import media from '../../styles/media';
import palette from '../../styles/palette';
import typography from '../../styles/typography';

import ConsultationCategory from '../../types/ConsultationCategory';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

import useAuth from '../../hooks/useAuth';
import useLogin from '../../hooks/useLogin';

import { SidebarContainer, SidebarImage } from '../Sidebar';
import ProgressSection from '../answerLoading/ProgressSection';
import MarkdownContent from '../common/MarkdownContent';
import KakaoLoginButton from '../common/KakaoLoginButton';
import GreyNoticeText from '../common/GreyNoticeText';
import { MessageRole } from '../../types/ConsultationDetail';

const Container = styled.div<{ messagedRole: MessageRole }>`
  display: flex;
  gap: 0.5em;
  justify-content: ${({ messagedRole }) => (messagedRole === 'expert' ? 'flex-start' : 'flex-end')};

  ${media.isMobile`
    gap: 0.25em;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;

  ${media.isMobile`
    gap: 0.25em;
  `}

  h4 {
    color: ${palette.textCaptionGrey};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.caption}
    `}
  }
`;

const Answer = styled.div<{ expandAnimation: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1em;

  max-width: 40em;
  width: fit-content;
  border-radius: 12px;
  border: 1px solid ${palette.containerLightGrey};
  background-color: ${palette.containerWhite};
  padding: 0.5em 1em;

  ${media.isMobile`
    padding: 0.5em 0.625em;
  `}
  overflow: hidden;
  animation: ${({ expandAnimation }) => (expandAnimation ? 'expand 0.5s ease' : 'none')} ;

  @keyframes expand {
    from {
      clip-path: inset(0 0 100% 0);
      opacity: 0;
    }
    to {
      clip-path: inset(0 0 0 0);
      opacity: 1;
    }
  }
`;

const Question = styled.p<{ category: ConsultationCategory }>`
  max-width: 33.75em;
  width: fit-content;
  border-radius: 12px;
  background-color: ${({ category }) => (consultationCategoryDictionary[category].color.superlight)};
  padding: 0.5em 1em;
  ${typography.body1}
  color: ${palette.textBlack};
  white-space: pre-wrap;
  
  ${media.isMobile`
    padding: 0.5em 0.625em;
    max-width: 17.5em;
  `}
`;

const Loading = styled.div`
  width: 22.5em;
  border-radius: 12px;
  border: 1px solid ${palette.containerLightGrey};
  padding: 0.5em 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;

  section {
    min-height: auto;
  }

  ${media.isMobile`
    padding: 0.5em 0.625em;
    width: 100%;
    gap: 0.25em;
  `}
`;

const InduceLoginBox = styled.div`
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;

  h4 {
    color: ${palette.textBlack};
    ${typography.heading4}
    white-space: pre-wrap;
    text-align: center;

    ${media.isMobile`
      ${typography.heading4Mobile}
    `}
  }

  button {
    width: 100%;
  }
`;

export default function Message({
  content,
  messageRole,
  category,
  focusedMessageRef = null,
  isFirstMessage = false,
} :{
  content: string;
  messageRole: MessageRole | 'loading';
  category: ConsultationCategory;
  focusedMessageRef?: Ref<HTMLDivElement>;
  isFirstMessage? : boolean;
}) {
  const { isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();
  const { expertName, expertRole, profileImage } = consultationCategoryDictionary[category];

  if (messageRole === 'expert') {
    return (
      <Container messagedRole={messageRole} ref={focusedMessageRef}>
        <SidebarContainer>
          <SidebarImage src={profileImage} alt={expertName} />
        </SidebarContainer>
        <Content>
          <h4>
            {expertName}
            {' '}
            -
            {' '}
            {expertRole}
          </h4>
          <Answer
            expandAnimation={focusedMessageRef !== null}
          >
            <MarkdownContent
              content={content}
              category={category}
            />
            {
              isFirstMessage && (category === 'legal' || category === 'medical')
                ? (
                  <GreyNoticeText>
                    {`※ 본 상담은 AI 상담으로 참고용이며, 구체적인 사안에 적용하는 경우 반드시 
                    ${category === 'legal' ? '법률' : '의료'}분야 종사자의 도움을 받으시기 바랍니다.`}
                  </GreyNoticeText>
                )
                : null
            }
          </Answer>
        </Content>
      </Container>
    );
  }

  if (messageRole === 'client') {
    return (
      <Container messagedRole={messageRole} ref={focusedMessageRef}>
        <Question category={category}>{content}</Question>
      </Container>
    );
  }

  return (
    <Container messagedRole="expert" ref={focusedMessageRef}>
      <SidebarContainer>
        <SidebarImage src={profileImage} alt={expertName} />
      </SidebarContainer>
      <Content>
        <h4>
          {expertName}
          {' '}
          -
          {' '}
          {expertRole}
        </h4>
        <Loading>
          <ProgressSection type={category} />
          {isLoggedIn() || (
          <InduceLoginBox>
            <h4>
              3초만에 로그인하고
              {'\n'}
              무제한 무료로 질문하세요
            </h4>
            <KakaoLoginButton
              id="login-button"
              className="in-consulting"
              onClick={navigateLogin}
            />
          </InduceLoginBox>
          )}
        </Loading>
      </Content>
    </Container>
  );
}

Message.defaultProps = {
  focusedMessageRef: null,
  isFirstMessage: false,
};
