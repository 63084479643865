import { SajuBirthKey } from '../types/SajuBirth';
import twelveGanjiTimeTable from './twelveGanjiTimeTable';

const sajuDropDownOptions:{
  [Key in SajuBirthKey] : string[]
} = {
  year: Array.from({ length: 2024 - 1920 + 1 }, (_, i) => `${2024 - i}년`),
  month: [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ],
  day: [
    '1일',
    '2일',
    '3일',
    '4일',
    '5일',
    '6일',
    '7일',
    '8일',
    '9일',
    '10일',
    '11일',
    '12일',
    '13일',
    '14일',
    '15일',
    '16일',
    '17일',
    '18일',
    '19일',
    '20일',
    '21일',
    '22일',
    '23일',
    '24일',
    '25일',
    '26일',
    '27일',
    '28일',
    '29일',
    '30일',
    '31일',
  ],
  hour: Object.keys(twelveGanjiTimeTable),
  gender: [
    '남자',
    '여자',
  ],
};

export default sajuDropDownOptions;
