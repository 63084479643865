import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { ChangeEvent, useEffect, useRef } from 'react';

import media from '../../styles/media';
import palette from '../../styles/palette';

import useAuth from '../../hooks/useAuth';
import useClientMessageForm from '../../hooks/useClientMessageForm';
import useConsultationDetail from '../../hooks/useConsultationDetail';
import useLogin from '../../hooks/useLogin';
import useVisit from '../../hooks/useVisit';
import useInquiryAutoWrite from '../../hooks/useInquiryAutoWrite';

import checkIsGuestTrialEnd from '../../service/checkIsGuestTrialEnd';

import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';
import assetSource from '../../data/assetSource';

import ConsultationCategory from '../../types/ConsultationCategory';

import QuestionInput from './QuestionInput';
import QuestionSubmit from './QuestionSubmit';
import InduceLoginSection from './InduceLoginSection';
import OpenExpertRecommendationFormButton from './OpenExpertRecommendationFormButton';

const Container = styled.div<{ noBorderTop: boolean; }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 0.5em;
  border-top: ${({ noBorderTop }) => (noBorderTop ? 'none' : `1px solid ${palette.containerLightGrey}`)};

  ${media.isMobile`
    border-top: 1px solid ${palette.containerLightGrey};
    padding: 0.5em 0.75em;
  `}
`;

const ClientActionContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export default function ClientMessageForm({
  isStartConsultationPage,
  consultationCategory,
}: {
  isStartConsultationPage: boolean;
  consultationCategory: ConsultationCategory;
}) {
  const { isAnswering, dialogue } = useConsultationDetail();
  const {
    updateClientMessage, consult, clientMessage, ask,
  } = useClientMessageForm();
  const { autoWrite } = useInquiryAutoWrite();
  const { isGuestTrialEnd, isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();
  const { guestId } = useVisit();
  const isLoadingRef = useRef<boolean>(false);

  useEffect(() => {
    autoWrite();
  }, [autoWrite, updateClientMessage]);

  const handleChangeInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateClientMessage(event.target.value);
  };

  const handleClickSubmit = async () => {
    // 더블클릭 방지 로직
    if (isLoadingRef.current) {
      return;
    }
    isLoadingRef.current = true;

    if (isStartConsultationPage) {
      if (!isLoggedIn()) {
        const isTrialEnd = await checkIsGuestTrialEnd(guestId);
        if (isTrialEnd) {
          navigateLogin();
          return;
        }
      }
      await consult(consultationCategory);
      return;
    }
    if (isGuestTrialEnd(dialogue)) {
      navigateLogin();
      return;
    }
    await ask();

    isLoadingRef.current = false;
  };

  const startConsultationMinRow = () => {
    if (isAnswering) return 1;
    if (consultationCategory === 'mumul' || consultationCategory === 'admission') return 2;
    return 3;
  };

  const isSubmittable = !isAnswering && clientMessage !== '';

  if (isStartConsultationPage) {
    return (
      <Container noBorderTop={false}>
        <QuestionInput>
          <TextareaAutosize
            maxRows={5}
            minRows={startConsultationMinRow()}
            placeholder={isAnswering
              ? '질문을 입력하세요...'
              : consultationCategoryDictionary[consultationCategory].questionPlaceHolder}
            value={clientMessage}
            onChange={handleChangeInput}
          />
          <QuestionSubmit
            inactive={!isSubmittable}
            consultationCategory={consultationCategory}
          >
            <button id="inquiry-button" type="button" onClick={handleClickSubmit}>
              {isSubmittable
                ? <img src={`${assetSource}/assets/send-button.svg`} alt="보내기 버튼" />
                : <img src={`${assetSource}/assets/send-button-inactive.svg`} alt="보내기 버튼 비활성화" />}
            </button>
          </QuestionSubmit>
        </QuestionInput>
      </Container>
    );
  }

  return (
    <Container noBorderTop={isGuestTrialEnd(dialogue)}>
      {isGuestTrialEnd(dialogue)
          && <InduceLoginSection />}
      <ClientActionContainer>
        {consultationCategory === 'mumul' || (
        <OpenExpertRecommendationFormButton
          inActive={isAnswering}
          consultationCategory={consultationCategory}
        />
        )}
        <QuestionInput>
          <TextareaAutosize
            maxRows={3}
            minRows={1}
            placeholder="질문을 입력하세요..."
            value={clientMessage}
            onChange={handleChangeInput}
          />
          <QuestionSubmit
            inactive={!isSubmittable}
            consultationCategory={consultationCategory}
          >
            <button type="button" onClick={handleClickSubmit}>
              {isSubmittable
                ? <img src={`${assetSource}/assets/send-button.svg`} alt="보내기 버튼" />
                : <img src={`${assetSource}/assets/send-button-inactive.svg`} alt="보내기 버튼 비활성화" />}
            </button>
          </QuestionSubmit>
        </QuestionInput>
      </ClientActionContainer>
    </Container>
  );
}
