import ConsultationCategory from '../types/ConsultationCategory';

type CategoryDictionary = {
  [key in ConsultationCategory]: string;
};
const categoryDictionary: CategoryDictionary = {
  legal: '법률',
  admission: '입시',
  saju: '사주',
  medical: '의료',
  insurance: '보험',
  mumul: '무물',
};

export default function categoryToKorean(category: ConsultationCategory) {
  return categoryDictionary[category];
}
