import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import checkIsFirstSajuConsultation from '../../service/checkIsFirstSajuConsultation';

import useBirthInput from './useBirthInput';

import isFirstSajuConsultationState from '../../stores/isFirstSajuConsultationState';

export default function useIsFirstSajuConsultation() {
  const [isFirstSajuConsultation, setIsFirstSajuConsultation] = useRecoilState(
    isFirstSajuConsultationState,
  );
  const { setSajuBirth } = useBirthInput();

  const loadIsFirstSajuConsultation = useCallback(async (
    isStartConsultationPage: boolean,
    consultationId?:string,
  ) => {
    const { isFirst, birth } = await checkIsFirstSajuConsultation(consultationId);

    setIsFirstSajuConsultation(isFirst);

    if (isStartConsultationPage && !isFirst && birth) {
      setSajuBirth(birth);
    }
  }, [setIsFirstSajuConsultation, setSajuBirth]);

  return {
    loadIsFirstSajuConsultation,
    isFirstSajuConsultation,
  };
}
