import styled from 'styled-components';

import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import CloseIcon from './CloseIcon';
import ModalActionButton from './ModalActionButton';

const Container = styled.div`
  padding: 0.5em;
  padding-bottom: 2em; 
  min-width: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  border-radius: 10px;
  border: 0.5px solid #D9D9D9;
  background-color: ${palette.containerWhite};
  ${media.isMobile`
    padding: 1em;
    align-items: flex-start;
  `}
  p {
    ${typography.body1}
    text-align: center;
    white-space: pre-wrap;
    ${media.isMobile`
      ${typography.body1}
      text-align: left;
      padding-inline: 0.5em;
    `}
  }
`;

const Head = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  ${media.isMobile`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  `}
  div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    ${media.isMobile`
      width: fit-content;
    `}
  }
  h1 {
    ${typography.heading3}
    ${media.isMobile`
      ${typography.heading2Mobile}
      padding-inline: 0.5em;
    `}
  }
`;

const RewriteButton = styled(ModalActionButton)``;

export default function YoonEventModal({ onClose } : {
  onClose : () => void;
}) {
  return (
    <Container>
      <Head>
        <div>
          <CloseIcon onClick={onClose} />
        </div>
        <h1>
          AI 변호사가 답해드립니다
        </h1>
      </Head>
      <p>
        윤석열 대통령의 계엄선포,
        {'\n'}
        합법일까요? 불법일까요?
        {'\n\n'}
        전시 상황이 아님에도 비상계엄을 선포,
        {'\n'}
        탄핵 가능할까요?
      </p>
      <RewriteButton type="button" onClick={onClose}>
        지금 바로 물어보기
      </RewriteButton>
    </Container>
  );
}
