import { SajuBirth } from '../types/SajuBirth';
import bokeAPI from './bokeAPI';

interface CheckPreviousSajuBirth {
  isFirst :boolean;
  birth: SajuBirth | null;
}
export default async function checkIsFirstSajuConsultation(
  consultationId?: string,
): Promise<CheckPreviousSajuBirth> {
  const { data } = await bokeAPI.post('/saju-consultations/user/is-first', {
    consultationId: consultationId || null,
  });
  return data;
}
