import styled from 'styled-components';

import { useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';

import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import assetSource from '../../data/assetSource';

const Container = styled.div<{ isHover: boolean; }>`
  ${typography.body1Bold}
  color: ${palette.textTone};
  padding: 0.25em 0.5em;
  box-sizing: content-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 0.625em;
  cursor: pointer;

  outline: ${({ isHover }) => (isHover ? `1px solid ${palette.textLightGrey}` : 'none')};

  ${media.isMobile`
    padding: 0;
    outline: none;
  `}

  img {
    display: ${({ isHover }) => (isHover ? 'block' : 'none')};

    ${media.isMobile`
      display: none;
    `}
  }
`;

export default function ConsultationItem({ id, title } : {
  id : string;
  title : string;
}) {
  const navigate = useNavigate();

  const hoverRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(hoverRef);

  const linkToConsultation = () => {
    navigate(`/consultations/${id}`);
  };
  return (
    <Container id="my-consultation-button" ref={hoverRef} isHover={isHover} role="button" onClick={linkToConsultation}>
      <p>{title}</p>
      <img src={`${assetSource}/assets/Arrow_right_gray.svg`} alt="상담 가기" />
    </Container>
  );
}
