import { useCallback } from 'react';

import useModal from './useModal';
import useQueryParams from './useQueryParams';
import useGuestMessageMemory from './useGuestMessageMemory';

import KinWelcomeModal from '../components/modal/KinWelcomeModal';
import YoonEventModal from '../components/modal/YoonEventModal';
import useClientMessageForm from './useClientMessageForm';

export default function useInquiryAutoWrite() {
  const { openModal } = useModal();
  const { naverInquiry, yoonInquiry } = useQueryParams();
  const { extractGuestMessage } = useGuestMessageMemory();
  const { updateClientMessage } = useClientMessageForm();

  const autoWrite = useCallback(() => {
    const guestMessage = extractGuestMessage();
    if (guestMessage) {
      updateClientMessage(guestMessage);
      return;
    }

    if (yoonInquiry) {
      openModal({
        Component: YoonEventModal,
      });
      updateClientMessage(yoonInquiry);
      return;
    }

    if (naverInquiry) {
      openModal({
        Component: KinWelcomeModal,
      });
      updateClientMessage(naverInquiry);
    }
  }, [extractGuestMessage, naverInquiry, openModal, updateClientMessage, yoonInquiry]);

  return {
    autoWrite,
  };
}
