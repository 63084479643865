import styled from 'styled-components';
import typography from '../../styles/typography';
import palette from '../../styles/palette';
import ConsultationCategory from '../../types/ConsultationCategory';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';
import media from '../../styles/media';

const Container = styled.button<{ category: ConsultationCategory; isActive:boolean; }>`
  padding: 12px 16px;
  border-radius: 25px;
  background-color: ${({ isActive, category }) => (isActive ? consultationCategoryDictionary[category].color.original : palette.containerLightGrey)};
  color: ${palette.textWhite};
  cursor: pointer;
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};

  ${typography.body1Button}

  ${media.isMobile`
    padding: 8px 12px;
  `}
  
  ${media.isPc`
      &:hover {
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.20);
        background-color: ${({ category }: { category:ConsultationCategory; }) => `${consultationCategoryDictionary[category].color.dark};`}
      }
  `}
`;

export default function RoundButtonByCategory({
  id, className, text, onClick, isActive, consultationCategory,
}:
{ id?: string;
  className?: string;
  text: string;
  onClick: () => void;
  isActive:boolean;
  consultationCategory: ConsultationCategory; }) {
  return (
    <Container
      id={id}
      className={className}
      isActive={isActive}
      category={consultationCategory}
      disabled={!isActive}
      type="button"
      onClick={onClick}
    >
      {text}
    </Container>
  );
}

RoundButtonByCategory.defaultProps = {
  id: '',
  className: '',
};
