import bokeAPI from './bokeAPI';

export default async function createAnswer({
  consultationId,
  message,
}: {
  consultationId: string;
  message: string;
}) {
  await bokeAPI.post(`/consultations/${consultationId}/answers`, {
    message,
  });
}
