/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components';
import palette from '../../styles/palette';
import typography from '../../styles/typography';

type InfoType = 'info' | 'alert';

const Container = styled.div<{ infoType: InfoType, isChecked: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5em;
  gap: 0.5em;
  border-radius:  ${({ isChecked }) => (isChecked ? '5px' : '0')};
  background-color: ${({ isChecked, infoType }) => (
    isChecked
    && (infoType === 'info' ? `${palette.superlightGreen}` : `${palette.superlightRed}`)
  )};

  /* 체크박스를 숨기기 */
  input {
    display: none;
  }

  /* 체크박스를 감싸는 라벨 */
  label {
    display: inline-flex;
    cursor: pointer;
    /* 커스텀 체크박스 */
    span {
      width: 1em;
      height: 1em;
      border: 2px solid ${palette.textDarkGrey};
      border-radius: 4px; 
      position: relative;
      transition: background-color 0.1s ease, border-color 0.1s ease;
    }
    /* 체그되었을 때 체크박스 */
    input:checked + span {
      background-color: ${({ isChecked, infoType }) => (isChecked && (infoType === 'info' ? `${palette.darkGreen}` : `${palette.darkRed}`))};
      border-color: ${({ isChecked, infoType }) => (isChecked && (infoType === 'info' ? `${palette.darkGreen}` : `${palette.darkRed}`))};
          
      /* 체크되었을 때 나타나는 체크표시 */
      &::after {
        content: ""; /* 체크 표시 내용 */
        position: absolute;
        top: 0; /* 체크 표시 위치 조절 */
        left: 3px; /* 체크 표시 위치 조절 */
        width: 4px; /* 체크 표시 크기 */
        height: 8px; /* 체크 표시 크기 */
        border: solid ${palette.textWhite}; /* 체크 표시 색상 */
        border-width: 0 2px 2px 0; /* 체크 표시 모양 만들기 */
        transform: rotate(45deg); /* 체크 표시 회전 */
      }
    }
  }

  p {
    ${typography.caption}
    color: ${palette.primary};
  }
`;

export default function InfoCheckBox({
  isChecked,
  handleChangeChecked,
  infoText,
  infoType,
}:{
  isChecked: boolean;
  handleChangeChecked: (isChecked: boolean) => void;
  infoText: string;
  infoType: InfoType;
}) {
  return (
    <Container infoType={infoType} isChecked={isChecked}>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => handleChangeChecked(e.target.checked)}
        />
        <span />
      </label>
      <p>{infoText}</p>
    </Container>
  );
}
