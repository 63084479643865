import { useRecoilState, useResetRecoilState } from 'recoil';
import { useCallback, useEffect } from 'react';

import twelveGanjiTimeTable from '../../data/twelveGanjiTimeTable';
import sajuBirthState from '../../stores/sajuBirthState';
import { SajuBirthKey } from '../../types/SajuBirth';

export default function useBirthInput() {
  const [sajuBirth, setSajuBirth] = useRecoilState(sajuBirthState);
  const initializeSajuBirth = useResetRecoilState(sajuBirthState);

  useEffect(() => {
    setSajuBirth((prev) => ({
      ...prev,
      hour: sajuBirth.birthTimeUnknown ? '' : prev.hour,
    }));
  }, [sajuBirth.birthTimeUnknown, setSajuBirth]);

  const updateSajuBirth = useCallback((key: SajuBirthKey, value : string) => {
    setSajuBirth((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, [setSajuBirth]);

  const updateIsMoonCalendar = useCallback((isMoon : boolean) => {
    setSajuBirth((prev) => ({
      ...prev,
      calendar: isMoon ? 'M' : 'S',
    }));
  }, [setSajuBirth]);

  const handleChangeBirthTimeUnknown = useCallback((isChecked : boolean) => {
    setSajuBirth((prev) => ({
      ...prev,
      birthTimeUnknown: isChecked,
    }));
  }, [setSajuBirth]);

  const doneSajuBirthInput = useCallback(() => {
    const {
      year, month, day, hour, birthTimeUnknown, gender,
    } = sajuBirth;

    return (year && month && day && (hour || birthTimeUnknown) && gender);
  }, [sajuBirth]);

  const composeMessageFromBirth = useCallback(() => {
    const {
      year, month, day, hour, gender, calendar, birthTimeUnknown,
    } = sajuBirth;

    const sajuInquiry = `저는 ${calendar === 'M' ? '음력' : '양력'} ${year} ${month} ${day}생 ${gender}입니다.
${birthTimeUnknown
    ? '태어난 시간은 잘 모르겠어요.'
    : `${hour.replace(' ~ ', '에서 ')} 사이인 ${twelveGanjiTimeTable[hour]}에 태어났어요.`}`;

    return sajuInquiry;
  }, [sajuBirth]);

  return {
    sajuBirth,
    initializeSajuBirth,
    updateSajuBirth,
    updateIsMoonCalendar,
    handleChangeBirthTimeUnknown,
    doneSajuBirthInput,
    composeMessageFromBirth,
    setSajuBirth,
  };
}
