import {
  Route, Routes, BrowserRouter,
} from 'react-router-dom';
import { useEffect } from 'react';

import useVisit from './hooks/useVisit';

import PageLayout from './components/layouts/PageLayout';

import LoginPage from './pages/LoginPage';
import ConsultationsPage from './pages/ConsultationsPage';
import OAuthPage from './pages/OAuthPage';
import LegalConsultationPage from './pages/LegalConsultationPage';
import LegalPage from './pages/LegalPage';
import SajuPage from './pages/SajuPage';
import SajuConsultationPage from './pages/SajuConsultationPage';
import HomePage from './pages/HomePage';
import ConsultationRoutePage from './pages/ConsultationRoutePage';
import MedicalPage from './pages/MedicalPage';
import AdmissionPage from './pages/AdmissionPage';
import MedicalConsultationPage from './pages/MedicalConsultationPage';
import AdmissionConsultationPage from './pages/AdmissionConsultationPage';
import MumulPage from './pages/MumulPage';
import MumulConsultationPage from './pages/MumulConsultationPage';
import InsurancePage from './pages/InsurancePage';
import InsuranceConsultationPage from './pages/InsuranceConsultationPage';

export default function Router() {
  const { visit } = useVisit();

  useEffect(() => {
    setTimeout(visit, 500);
  }, [visit]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageLayout isHomepage withFooter />}>
          <Route path="/" element={<HomePage />} />
        </Route>
        <Route path="/" element={<PageLayout withFooter={false} isConsultationPage />}>
          <Route path="/consultations/legal" element={<LegalPage />} />
          <Route path="/consultations/saju" element={<SajuPage />} />
          <Route path="/consultations/medical" element={<MedicalPage />} />
          <Route path="/consultations/admission" element={<AdmissionPage />} />
          <Route path="/consultations/insurance" element={<InsurancePage />} />
          <Route path="/consultations/mumul" element={<MumulPage />} />
          <Route path="/consultations/:consultationId" element={<ConsultationRoutePage />} />
          <Route path="/consultations/legal/:consultationId" element={<LegalConsultationPage />} />
          <Route path="/consultations/saju/:consultationId" element={<SajuConsultationPage />} />
          <Route path="/consultations/medical/:consultationId" element={<MedicalConsultationPage />} />
          <Route path="/consultations/admission/:consultationId" element={<AdmissionConsultationPage />} />
          <Route path="/consultations/insurance/:consultationId" element={<InsuranceConsultationPage />} />
          <Route path="/consultations/mumul/:consultationId" element={<MumulConsultationPage />} />
        </Route>
        <Route path="/" element={<PageLayout withFooter />}>
          <Route path="/consultations" element={<ConsultationsPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/oauth" element={<OAuthPage />} />
      </Routes>
    </BrowserRouter>
  );
}
