import { useLayoutEffect } from 'react';

import ConsultationCategory from '../../types/ConsultationCategory';

import useClientMessageForm from '../../hooks/useClientMessageForm';
import useHeaderHeight from '../../hooks/useHeaderHeight';

import ConsultationPageLayout from './ConsultationPageLayout';
import ConsultationHistory from './ConsultationHistory';
import ClientMessageForm from './ClientMessageForm';
import BirthInfoForm from '../saju/BirthInfoForm';
import useConsultationDetail from '../../hooks/useConsultationDetail';

export default function StartConsultationPageContent({
  consultationCategory,
}: {
  consultationCategory: ConsultationCategory
}) {
  const { initializeClientMessage } = useClientMessageForm();
  const { initializeConsultationDetail, isAnswering } = useConsultationDetail();
  const { headerHeight } = useHeaderHeight();
  useLayoutEffect(() => {
    initializeConsultationDetail();
    initializeClientMessage();
  }, [initializeClientMessage, initializeConsultationDetail]);

  const renderInputForm = () => {
    if (consultationCategory !== 'saju') {
      return (
        <ClientMessageForm
          isStartConsultationPage
          consultationCategory={consultationCategory}
        />
      );
    }

    if (isAnswering) {
      return (
        <ClientMessageForm
          isStartConsultationPage={false}
          consultationCategory={consultationCategory}
        />
      );
    }

    return <BirthInfoForm />;
  };

  return (
    <ConsultationPageLayout headerHeight={headerHeight}>
      <ConsultationHistory
        isStartConsultationPage
        consultationCategory={consultationCategory}
      />
      {renderInputForm()}
    </ConsultationPageLayout>
  );
}
