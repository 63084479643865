import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import ConsultationCategory from '../types/ConsultationCategory';

import fetchConsultationCategory from '../service/fetchConsultationCategory';

import PageLoading from '../components/common/PageLoading';

export default function ConsultationRoutePage() {
  const { consultationId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!consultationId) {
      // TODO: 오류 처리
      return;
    }

    fetchConsultationCategory(consultationId).then(
      (consultationCategory: ConsultationCategory) => navigate(`/consultations/${consultationCategory}/${consultationId}`, { replace: true }),
    );
  }, [consultationId, navigate]);

  return (
    <PageLoading />
  );
}
