import palette from '../styles/palette';
import ConsultationCategory from '../types/ConsultationCategory';
import assetSource from './assetSource';

type ConsultationCategoryDictionary = {
  [Key in ConsultationCategory]: {
    navigatorCategory: string;
    welcomeInstruction: string;
    revisitInstruction: string;
    expertName: string;
    expertRole: string;
    profileImage: string;
    loadingSentences: string[];
    questionPlaceHolder: string;
    color: {
      original: string;
      light: string;
      superlight: string;
      dark: string;
    }
    expertRecommendation: {
      clientRequestMessage: string;
      expertResponseMessage: string;
      expertTitle: string;
      targetExpert: string;
    }
  }
};

export const categories: ConsultationCategory[] = ['legal', 'medical', 'saju', 'admission', 'insurance', 'mumul'];

const consultationCategoryDictionary: ConsultationCategoryDictionary = {
  legal: {
    navigatorCategory: '법률',
    welcomeInstruction: `안녕하세요, ***AI 로이어 벤자민***입니다.


저는 의뢰인님께 3가지를 약속드립니다.
***1) 24시간 실시간 상담***
***2) 100% 무료***
***3) 100% 비밀보장***


첫째, ***시간 순서*** 및 ***육하원칙으로*** 작성해주세요.
둘째, ***질문이 구체적일수록*** 상담의 질이 높아집니다.`,
    revisitInstruction: '',
    expertName: '벤자민',
    expertRole: 'AI 로이어',
    profileImage: `${assetSource}/assets/benjamin-card.png`,
    loadingSentences: [
      '대법원 판례를 다시 읽어보고 있습니다.',
      '법원에 잠시 다녀오고 있습니다.',
      '노크 AI로 사건을 정리 중입니다.',
      '리걸테크 법안이 제정되고 있습니다.',
      '관련 법령을 다시 읽어보고 있습니다.',
      '노크 AI로 사건을 분석 중입니다.',
      '유사 사례를 확인 중입니다.',
      '노크 AI로 법령을 분석 중입니다.',
      '법전을 다시 살펴보고 있습니다.',
      '노크 AI로 조언을 작성하고 있습니다.',
      '민법과 형법을 분석하고 있습니다.',
    ],
    questionPlaceHolder: '1. 시간 순으로 작성해주세요.\n2. 육하원칙에 따라 작성해주세요.\n3. 질문 내용을 자세히 작성해주세요.',
    color: {
      original: `${palette.orange}`,
      light: `${palette.lightOrange}`,
      superlight: `${palette.superlightOrange}`,
      dark: `${palette.darkOrange}`,
    },
    expertRecommendation: {
      clientRequestMessage: '현재 상황에 대해 더 구체적이고 전문적인 법률 상담이 필요합니다.\n실제 변호사와 직접 연결될 수 있도록 요청드립니다.',
      expertResponseMessage: '네, 잘 결정하셨습니다. 저희 팀에서 변호사를 연결해 입력하신 번호로 연락드리겠습니다.',
      expertTitle: '변호사 만나기',
      targetExpert: '변호사',
    },
  },
  admission: {
    navigatorCategory: '생기부',
    welcomeInstruction: `안녕하세요, ***AI 생기부 컨설턴트 창식쌤***입니다.
공부도 힘든데, 생기부까지 신경쓰려니 힘드시죠?


우선 ***목표 학과***와 ***학년***을 써주세요.
예시:
***목표 학과: 경제학과***
***학년: 고2***


그리고, ***생기부에 관련된 무엇이든*** 물어보세요.
예시:
***(리서치)*** 낙수효과 VS 소득주도성장
***(리서치)*** 계엄령 관련 보고서를 쓰려고 하는데, 내란죄가 되려면 뭐가 필요하죠?
***(보고서)*** "빅데이터를 활용한 소비자 구매 패턴 분석"을 주제로 보고서를 작성해주세요.
***(보고서)*** "시진핑 3연임의 정당성에 대한 중국 국내 반응"을 주제로 영어로 보고서를 작성해주세요.
***(세특주제추천)*** 약학과에 어울리는 화법과 작문 세특 주제를 추천해주세요.
***(도서추천)*** 화학생물공학부랑 봉사 정신을 엮을 수 있는 도서를 추천해주세요.
***(과목선택)*** 서울대 정외과 가려면 수학 과목 선택 어떻게 하면 좋을까요?
***(세특작성)*** 식품생명공학과 관련해서 "인삼 속 사포닌의 곰팡이 번식 억제 효과 연구"를 했는데, 선생님께 들고 갈 실제 세특 내용을 써주세요.
***(생기부조언)*** 건축학과를 가고 싶은데 생기부에 관련 내용을 넣은 적이 없어요. 고3까지 생기부를 어떻게 챙겨야할지 알려주세요.
`,
    revisitInstruction: '',
    expertName: '창식쌤',
    expertRole: 'AI 생기부 컨설턴트',
    profileImage: `${assetSource}/assets/changsikssam-card-v2.png`,
    loadingSentences: [
      '서울대생 생기부를 읽어보고 있습니다.',
      '그들의 생기부에 갔다오고 있습니다.',
      '노크 AI로 생기부 전략을 수립 중입니다.',
      '생기부 예시를 조회하고 있습니다.',
      '서울대 입학처에 다녀오고 있습니다.',
      '노크 AI로 생기부 전략을 추론 중입니다.',
      '합격생 수기를 읽어보고 있습니다.',
      '노크 AI로 선배들의 생기부를 분석 중입니다.',
      '자동봉진 작성 요령을 검토하고 있습니다.',
    ],
    questionPlaceHolder: '1. 목표학과와 학년을 작성해주세요.\n2. 예시를 참고하여 질문을 작성해주세요.',
    color: {
      original: `${palette.violet}`,
      light: `${palette.lightViolet}`,
      superlight: `${palette.superlightViolet}`,
      dark: `${palette.darkViolet}`,
    },
    expertRecommendation: {
      clientRequestMessage: '현재 상황에 대해 더 구체적이고 전문적인 생기부 상담이 필요합니다.\n실제 생기부 컨설턴트와 직접 연결될 수 있도록 요청드립니다.',
      expertResponseMessage: '네, 잘 결정하셨습니다. 저희 팀에서 컨설턴트를 연결해 입력하신 번호로 연락드리겠습니다.',
      expertTitle: '생기부 컨설턴트 만나기',
      targetExpert: '컨설팅',
    },
  },
  medical: {
    navigatorCategory: '의료',
    welcomeInstruction: `안녕하세요, ***AI 의사 닥터송***입니다.


가벼운 통증부터 타인에게 말 못할 중질환까지,
***건강에 관련된 무엇이든*** 물어보세요.
평균 ***10초 안에*** 답변드립니다.


첫째, ***현재 증상***을 알려주세요.
둘째, ***병력, 복용 중인 약물*** 등을 작성해주세요.
셋째, ***질문이 구체적일수록*** 상담의 질이 높아집니다.`,
    revisitInstruction: '',
    expertName: '닥터송',
    expertRole: 'AI 의사',
    profileImage: `${assetSource}/assets/doctorsong-card-v3.png`,
    loadingSentences: [
      '노크 AI로 치료 방법을 확인 중입니다.',
      '관련된 처방을 확인하고 있습니다.',
      '노크 AI로 유사 사례를 검토 중입니다.',
      '환자의 증상을 분석하고 있습니다.',
      '건강 상태를 평가하고 있습니다.',
      '노크 AI로 의료 데이터를 분석 중입니다.',
      '최신 의학 연구를 참고하고 있습니다.',
      '서울대병원에 다녀오고 있습니다.',
      '노크 AI로 의료 기록을 검토 중입니다.',
    ],
    questionPlaceHolder: '1. 어디가 아프신지 구체적으로 작성해주세요.\n2. 병력과 복용약물을 알려주세요.\n3. 질문 내용을 자세히 작성해주세요.',
    color: {
      original: `${palette.blue}`,
      light: `${palette.lightBlue}`,
      superlight: `${palette.superlightBlue}`,
      dark: `${palette.darkBlue}`,
    },
    expertRecommendation: {
      clientRequestMessage: '현재 상황에 대해 더 구체적이고 전문적인 의료 상담이 필요합니다.\n제 상황과 적합한 병원 추천을 요청드립니다.',
      expertResponseMessage: '네, 잘 결정하셨습니다. 저희 팀에서 병원을 연결해 입력하신 번호로 연락드리겠습니다.',
      expertTitle: '병원 추천받기',
      targetExpert: '병원추천',
    },
  },
  insurance: {
    navigatorCategory: '보험',
    welcomeInstruction: `안녕하세요, ***AI 보험설계사 보미***입니다.


현재 가입하신 보험, 청구 서류, 청구 절차 등
***보험에 관련된 무엇이든*** 물어보세요.
***모든 내용은 100% 비밀보장***됩니다.


예시:
***(보험 청구 가능 여부)*** 제가 마트 주차장에서 후진을 하다가 건물을 살짝 박아서 찌그러졌어요. 수리비가 100만원 정도 나올 것 같다고 하는데 보험처리가 되나요?
***(보험 보장 범위)*** 메리츠화재 올바른 암보험 보장 범위 알려주세요.
***(보험 가입 필요 여부)*** 집합건물의 의무보험 가입 사항에 총 12세대의 3층 빌라도 집합건물 의무보험이 있나요?
***(보험 청구 서류, 절차)*** 일반병원에서 진단서를 끊어 보험회사에 보냈어요. 한의원 치료도 한다면 한의원에서도 진단서를 끊어서 보험사에 제출해야 하나요?
***(보험 설계 평가)*** 나이 46세 남자입니다. 폐암 수술을 한 적이 있습니다. 20년납 갱신형을 비갱신으로 갈아타려 합니다. 그래서 설계를 받았는데 적당한가요?
***(보험 상품 추천)*** 미국 장기 여행 해외장기체류 보험 어떤 것을 가입해야 하나요?
`,
    revisitInstruction: '',
    expertName: '보미',
    expertRole: 'AI 보험설계사',
    profileImage: `${assetSource}/assets/bomi-card.png`,
    loadingSentences: [
      '노크 AI로 고객님의 상황을 검토 중입니다.',
      '고객에게 맞는 보험 상품을 검토 중입니다.',
      '노크 AI로 유사 사례를 분석 중입니다.',
      '보험 약관 및 보장 범위를 분석하고 있습니다.',
      '최적의 보험 상품을 설계하고 있습니다.',
      '노크 AI로 보험 보장 내용를 확인 중입니다.',
      '최신 보험 상품 정보를 참고하고 있습니다.',
      '보험설계사를 만나고 오는 중입니다.',
      '노크 AI로 보험 데이터를 확인 중입니다.',
    ],
    questionPlaceHolder: '1. 나이와 과거병력을 입력해주세요.\n2. 가입하신 보험상품 이름을 적어주세요.\n3. 질문 내용을 자세히 작성해주세요.',
    color: {
      original: `${palette.insurance}`,
      light: `${palette.lightInsurance}`,
      superlight: `${palette.superlightInsurance}`,
      dark: `${palette.darkInsurance}`,
    },
    expertRecommendation: {
      clientRequestMessage: '현재 상황에 대해 더 구체적이고 전문적인 보험 상담이 필요합니다.\n제 상황에 적합한 보험설계사 연결을 요청드립니다.',
      expertResponseMessage: '네, 잘 결정하셨습니다. 저희 팀에서 보험설계사를 연결해 입력하신 번호로 연락드리겠습니다.',
      expertTitle: '보험설계사 만나기',
      targetExpert: '보험설계',
    },
  },
  saju: {
    navigatorCategory: '사주',
    welcomeInstruction: `안녕하세요, ***AI 사주 선생 도현(導賢)***입니다.


생년월일시를 입력 후 ***풀이하기를 누르시면***
내담자님의 ***과거와 미래***를 풀이해드립니다.


구체적인 고민이나 궁금증은
***첫 사주풀이를 받으신 후***에 질문해주세요.`,
    revisitInstruction: `안녕하세요, ***AI 사주 선생 도현(導賢)***입니다.


내담자님, 이번엔 어떤 고민으로 오셨나요?
***이전 생년월일***을 입력해놓았으니 사주풀이를 먼저 받아보세요.


구체적인 고민이나 궁금증은
***첫 사주풀이를 받으신 후***에 질문해주세요.`,
    expertName: '도현(導賢)',
    expertRole: 'AI 사주 선생님',
    profileImage: `${assetSource}/assets/dohyun-card-v2.png`,
    loadingSentences: [
      '시간의 흐름 속에서 답을 찾고 있습니다.',
      '당신만의 특별한 운세를 해석하고 있습니다.',
      '노크 AI로 사주팔자를 분석 중입니다.',
      '운명의 지도를 그리고 있습니다.',
      '삼명학을 다시 읽어보고 있습니다.',
      '노크 AI로 명리학 자료를 검토 중입니다.',
      '운명의 흐름을 읽고 있습니다.',
      '사주팔자에 따른 운세를 종합하고 있습니다.',
      '노크 AI로 성격을 추론하고 있습니다.',
    ],
    questionPlaceHolder: '',
    color: {
      original: `${palette.red}`,
      light: `${palette.lightRed}`,
      superlight: `${palette.superlightRed}`,
      dark: `${palette.darkRed}`,
    },
    expertRecommendation: {
      clientRequestMessage: '현재 상황에 대해 더 구체적이고 전문적인 사주 상담이 필요합니다.\n실제 역술가와 직접 연결될 수 있도록 요청드립니다.',
      expertResponseMessage: '네, 잘 결정하셨습니다. 저희 팀에서 역술가를 연결해 입력하신 번호로 연락드리겠습니다.',
      expertTitle: '역술가 만나기',
      targetExpert: '역술가',
    },
  },
  mumul: {
    navigatorCategory: '당신만의 전문가',
    welcomeInstruction: `안녕하세요, ***엘리***입니다.


무엇이든지 물어보세요.
***질문 수가 가장 많은 전문가를 개발해드립니다***.


예시:
(수의사) 강아지가 초콜릿을 먹었는데 괜찮은가요?
(컴퓨터 전문가) RTX 5080으로 이더리움 채굴 가능한가요?
(주식 전문가) 미국 ETF 추천 좀 해주세요.
(공인중개사) 근린생활시설 복비는 몇 퍼센트죠?
(과학쌤) 나비에-스토크스 방정식에 대해 알려주세요.`,
    revisitInstruction: '',
    expertName: '엘리',
    expertRole: '당신만의 전문가',
    profileImage: `${assetSource}/assets/elly-card-v3.png`,
    loadingSentences: [
      '정보의 바다에서 데이터를 찾는 중입니다.',
      '커피를 마시며 고민하고 있습니다.',
      '노크 AI로 질문을 분석하고 있습니다.',
      '데이터 맛집을 순례하고 있습니다',
      '지식의 퍼즐을 맞추고 있습니다.',
      '노크 AI로 정보를 수집하고 있습니다.',
      '지식 그래프를 탐색하고 있습니다.',
      '인공 회로를 풀가동 중입니다.',
      '노크 AI로 지식을 찾고 있습니다.',
    ],
    questionPlaceHolder: '1. 원하는 질문을 던져보세요.\n2. 무엇이든지 답해드립니다.',
    color: {
      original: `${palette.indigo}`,
      light: `${palette.lightIndigo}`,
      superlight: `${palette.superlightIndigo}`,
      dark: `${palette.darkIndigo}`,
    },
    expertRecommendation: {
      clientRequestMessage: '',
      expertResponseMessage: '',
      expertTitle: '',
      targetExpert: '',
    },
  },
};

export default consultationCategoryDictionary;
