import { useRecoilState } from 'recoil';
import { useCallback, useRef } from 'react';
import headerHeightState from '../stores/headerHeightState';

export default function useHeaderHeight() {
  const [headerHeight, setHeaderHeight] = useRecoilState(headerHeightState);
  const headerRef = useRef<HTMLDivElement>(null);
  const updateHeaderHeight = useCallback(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [setHeaderHeight]);
  return {
    headerHeight, headerRef, updateHeaderHeight,
  };
}
